import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

const { Link: AntLink } = Typography;

const Link = function ({ href, text }) {
  return (
    <AntLink
      href={href}
      target='_blank'
      className='custom-link'
    >
      <span className='custom-link__text'>{text}</span>
      <span className='custom-link__arrow' />
    </AntLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Link;
