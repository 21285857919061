import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import CalendarListRow from './CalendarListRow';

const CalendarList = function ({ data }) {
  return (
    <List
      className='calendar-list'
      itemLayout='horizontal'
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          className='calendar-list__item'
        >
          <CalendarListRow data={item} />
        </List.Item>
      )}
    />
  );
};

CalendarList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CalendarList;
