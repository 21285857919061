import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Row, Col, Typography, Space,
} from 'antd';
import Link from 'src/components/Link';

const { Title, Paragraph, Text } = Typography;

const CalendarListRow = function ({ data }) {
  return (
    <Row
      style={{ width: '100%' }}
      gutter={[
        {
          xs: 8,
          lg: 32,
        }, {
          xs: 8,
          lg: 32,
        },
      ]}
      className='calendar-list__row'
    >
      <Col
        xs={24}
        lg={8}
        className='calendar-list__row--time'
      >
        <Space
          direction='vertical'
          size={0}
        >
          <Text strong>{moment(data.eventStartDate).format('YYYY MMM D')}</Text>
          {data.eventStartTime && (
            <Text className='event-time'>{moment(data.eventStartTime, 'HH:mm').format('LT')}</Text>
          )}
          <Text>{data.eventPlace}</Text>
        </Space>
      </Col>
      <Col
        xs={24}
        lg={12}
        className='calendar-list__row--title'
      >
        <Title
          level={3}
          className='event-name'
        >
          {data.eventName}
        </Title>
        <Paragraph className='event-composer'>{data.eventDescription}</Paragraph>
        <Text className='event-role'>{data.eventRole}</Text>
      </Col>
      <Col
        xs={24}
        lg={4}
        className='calendar-list__row--tickets'
      >
        <Link
          href={data.eventLink}
          text='Tickets'
        />
      </Col>
    </Row>
  );
};

CalendarListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CalendarListRow;
