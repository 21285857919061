import { useMemo } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import filter from 'lodash/filter';
import reverse from 'lodash/reverse';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import parseInt from 'lodash/parseInt';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

function useGetEvents(data) {
  const today = moment().format('YYYY-MM-DD');

  const eventsSortedByDate = useMemo(() => {
    const eventStartDate = (date) => new Date(moment(date, 'YYYY-MM-DD HH:mm').format()).getTime();
    data.sort((a, b) => (
      eventStartDate(`${ get(a, 'eventStartDate') } ${ get(a, 'eventStartTime') || '00:00' }`)
        - eventStartDate(`${ get(b, 'eventStartDate') } ${ get(b, 'eventStartTime') || '00:00' }`)
    ));
    return data;
  }, [data]);

  const getAllYears = () => {
    const allYears = uniq(map(eventsSortedByDate, (event) => (
      parseInt(moment(event.eventStartDate).format('YYYY'))
    )));
    const years = [];
    forEach(allYears, (year) => {
      years.push(year);
      years.push(year + 1);
      years.push(year - 1);
    });

    return sortBy(uniq(years));
  };

  const years = getAllYears();
  const seasonMonthsStart = 8;
  const seasonMonthsEnd = 7;

  const seasonsEvents = useMemo(() => (
    filter(map(years, (year) => ({
      season: `${ year } / ${ year + 1 }`,
      events: filter(eventsSortedByDate, (event) => moment(event.eventStartDate).isBetween(`${ year }-${ seasonMonthsStart }-1`, `${ year + 1 }-${ seasonMonthsEnd }-1`)),
    })), (e) => !isEmpty(e.events))
  ), [eventsSortedByDate, years]);

  const pastEvents = useMemo(() => reverse(filter(eventsSortedByDate, (event) => (
    moment(today)
      .isAfter(event.eventStartDate)
  ))), [eventsSortedByDate, today]);

  const incomingEvents = useMemo(() => filter(eventsSortedByDate, (event) => (
    moment(today)
      .isBefore(event.eventStartDate)
  )), [eventsSortedByDate, today]);

  return {
    pastEvents,
    incomingEvents,
    seasonsEvents,
  };
}

export default useGetEvents;
